import React from 'react';
import WelcomeSection from './components/WelcomeSection';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/LoginSignup/Login';
import Signup from './components/LoginSignup/Signup';
import ForgotPassword from './components/LoginSignup/Forgotpassword'
//import Otp from './components/LoginSignup/Otp';
import { EmailContext } from './components/LoginSignup/EmailContext';
import ProtectedRoute from './components/ProtectedRoute';
import HomePage from './components/HomePage';

function App() {
  return (
    <div className="app">
      <EmailContext>
      <BrowserRouter>
      <Routes>
        <Route path='/' element= {<WelcomeSection />}></Route>
        <Route path='/login' element= {<Login/>}></Route>
        <Route path='/signup' element= {<Signup/>}></Route>
            <Route element={<ProtectedRoute />}>
              <Route path="/homepage" element={<HomePage />} />
            </Route>
        <Route path='/forgotpassword' element={<ForgotPassword/>}></Route>
        {/*<Route path='/otpverification' element={<Otp/>}></Route> */}
      </Routes>
      </BrowserRouter>
      </EmailContext>
    </div>
  );
}

export default App;
