import React, { useEffect, useState } from 'react';
import forgotr from '../assets/Loginwhiter.jpg'
import forgot from '../assets/otp.png'
import forgotl from '../assets/Loginwhitel.jpg'
import circler from '../assets/top.jpg'
import dotl from '../assets/topdot.png'
import dotr from '../assets/bottomdot.png'
import forgotb from '../assets/bottom.jpg'
import Cookies from 'js-cookie';
import './HomeCSS/HomePage.css'
function HomePage() {
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    // Retrieve the response from localStorage
    const storedResponseData = localStorage.getItem('user');
    if (storedResponseData) {
      setResponseData(JSON.parse(storedResponseData));
    } else {
      // If no response data is found, redirect to login
      window.location.href = '/login';
    }
  }, []);

  const handleLogout = () => {
    // Clear cookies and localStorage on logout
    Cookies.remove('token');
    localStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <div className='hcontainer'>
      <div className='hleft-section'>
        <img src ={forgotr} alt='vertical' className='img1'/>
        <img src={forgot} alt='doctor' className='img2'/>
        <img src={forgotl} alt='doctor' className='img3'/>
      </div>
      <div className='hright-section'>
      <img src={circler} alt= 'circler' className='img4'/>
      <img src={dotl} alt= 'circler' className='img5'/>
      <div className='hcenter'>
      <h1 style={{color:'#37304N'}}>Welcome to the Homepage!</h1>
      {responseData ? (
        <div>
        <h2>User Information</h2>
        <table >
          <tbody>
            <tr>
              <th>First Name:</th>
              <td>{responseData.provider.firstName}</td>
            </tr>
            <tr>
              <th>Last Name:</th>
              <td>{responseData.provider.lastName}</td>
            </tr>
            <tr>
              <th>Email:</th>
              <td>{responseData.provider.email}</td>
            </tr>
            <tr>
              <th>Phone:</th>
              <td>{responseData.provider.phone}</td>
            </tr>
            <tr>
              <th>Address:</th>
              <td>{responseData.provider.address}</td>
            </tr>
            <tr>
              <th>City:</th>
              <td>{responseData.provider.city}</td>
            </tr>
            <tr>
              <th>Zipcode:</th>
              <td>{responseData.provider.zipcode}</td>
            </tr>
            <tr>
              <th>Date of Birth:</th>
              <td>{new Date(responseData.provider.dob).toLocaleDateString()}</td>
            </tr>
            <tr>
              <th>Gender:</th>
              <td>{responseData.provider.gender}</td>
            </tr>
          </tbody>
        </table>
      
        {/* Display the issued token */}
      
        {/* Logout Button */}
        <button onClick={handleLogout} className='hlbutton'>Logout</button>
      </div>
      ) : (
        <p>Loading user data...</p>
      )}
      </div>
      <img src={dotr} alt='circler' className='img6'/>
      <img src={forgotb} alt='circler' className='img7'/>
                {/*<Modal show={showModal} message={errorMessage} onClose={()=>closeModal(typeError)} type={typeError} bname={buttonname}/>*/}
      </div>
    </div>
  );
}

export default HomePage;
