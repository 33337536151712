import React from 'react';
import dinosaurs from '../assets/dinosaurs.png';
import logo from '../assets/logo.png';
import top from '../assets/top.jpg'
import bottom from '../assets/bottom.jpg';
import topdot from '../assets/topdot.png'
import bottomdot from '../assets/bottomdot.png';
import whitet from '../assets/whitet.png';
import whiteb from '../assets/whiteb.jpg';
import { useNavigate } from 'react-router-dom';

const WelcomeSection = () => {
  const navigate = useNavigate();
  const signUp = ()=>{
    navigate('/signup');
  }
  function login() {
    navigate('/login');
  }
  return (
    <div className="container">
      <div className="left-section">
      <img className="white-right" src={whitet} alt = 'logo' style={{height:'140px'}}/>
      <header style={{  padding:'100px', }}>
      <div className='images'>
      <img src={logo} alt="C-Res Logo" className='image1' />
      <img src={dinosaurs} className='image2'  alt="Dinosaurs" />
      </div>
      </header>
      <img className="white-left" src={whiteb} alt = 'logo' style={{height:'140px'}}/>
    
      </div>

      <div className="right-section">
      <img className="dot-left" src={topdot} alt = 'logo' />
      <img className="circular-square-right" src={top} alt = 'logo'/>

        <h1>WELCOME</h1>
        <h2>C-Res Application</h2>
        <p>C-Recs is a healthcare app that helps users manage and track their health and wellness.</p>
        <div className="butt">
          <button className='register' type='submit' onClick={signUp}>Register</button>
          <button className= 'signin-bt' type='submit' onClick={login}>SignIn</button>
        </div>
        <img className="dot-right" src={bottomdot} alt = 'logo'/>
        <img className="circular-square-left" src={bottom} alt = 'logo' />
      </div>
    </div>
  );
};

export default WelcomeSection;
