import React from 'react';
import './CSS/Modal.css';
import circleu from '../../assets/circleu.png'
import circled from '../../assets/circled.png' // Create a separate CSS file for styling

 // Create a separate CSS file for styling

const Modal = ({ show,type, message,bname, onClose }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className='divide'>
                <img src={circleu} alt='circle' className='circleImg'/>
                <h2>{type}</h2>
                </div>
                <p>{message}</p>
                <div className='divide2'>
                <button onClick={onClose}  className="modal-close-button">{bname}</button>
                <img src={circled} alt='circle' className='circleImg2'/>
                </div>
            </div>
        </div>
    );
};

export default Modal;
