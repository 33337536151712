import {createContext,useState} from 'react';

const RegisteredEmail = createContext();

function EmailContext({children}){

    const [cemail,setCEmail] = useState('');

     const state = (data)=>{
         setCEmail(data);
     }
    return(
    <RegisteredEmail.Provider value={{cemail,state}}>
        {children}
    </RegisteredEmail.Provider>
    );
};

export {RegisteredEmail,EmailContext};