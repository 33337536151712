import React from 'react'
import forgotr from '../../assets/Loginwhiter.jpg'
import forgot from '../../assets/forgot.png'
import forgotl from '../../assets/Loginwhitel.jpg'
import circler from '../../assets/top.jpg'
import dotl from '../../assets/topdot.png'
import dotr from '../../assets/bottomdot.png'
import forgotb from '../../assets/bottom.jpg'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from './Modal'
import './CSS/forgot.css'

const ForgotPassword = ()=>{
    const [email,setEmail] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [typeError,setTypeError] = useState('');
    const [buttonname,setButtonName] = useState('');
    const navigate = useNavigate();


    const handleInputChange = (e) => {
       setEmail(e.target.value); 
      };


      const closeModal = (typeError) => {
        if(typeError === 'Error' || typeError === 'Success'){
          return setShowModal(false);
        } else {
          return  navigate('/login');
        }
      };  

      const handleSubmit = async(event) => {
        // Prevent default form submission
        event.preventDefault();

        let errors = '';
        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

        if (!email || !emailRegex.test(email)) {
            errors = 'Please enter a valid email';
        }

        setError(errors);

        if (!errors) {
            setIsSubmitting(true);
            // You can proceed with form submission
            console.log('Email:', email);
            try {
                // Replace 'https://your-api-endpoint/verify-otp' with your actual API endpoint
                const response = await fetch('https://seerecs.org/provider/forgotPassword', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  mode:'cors',
                  body: JSON.stringify({ email : email }),
                });
                const data = await response.json();
          
                if (!response.ok) {
                  console.log("Response error data: ", data);
                  
                  throw new Error(data.message);
                }
          
                if (data.success) {
                  // OTP verification successful, handle success
                  console.log('Email sent successfully');
                  setErrorMessage(data.message);
                  setTypeError('Congrats!');
                  setButtonName('SignIn');
                  setShowModal(true);
                    
                } else {
                  setError('Invalid email');
                  setErrorMessage(data.message);
                  setTypeError('Error');
                  setButtonName('Continue');
                  setShowModal(true);
                }
              } catch (error) {
                console.error('Error verifying email:', error);
                setError('Failed to verify email');
                setShowModal(true);
                setErrorMessage(error.message);
                setTypeError('Error');
                setButtonName('Continue');
              } finally {
                setIsSubmitting(false);
              }
        }
    }

    return (
        <div className='fcontainer'>
            <div className='fleft-section'>
            <img src ={forgotr} alt='vertical' className='img1'/>
            <img src={forgot} alt='doctor' className='img2'/>
            <img src={forgotl} alt='doctor' className='img3'/>
            </div>

            <div className='fright-section'>
                <img src={circler} alt= 'circler' className='img4'/>
                <img src={dotl} alt= 'circler' className='img5'/>
                <div className='fcenter'>
                    <h1>Forgot your password?</h1>
                    <p style={{fontSize:22}}>No problem, we're here to help.</p>
                    <div className='ftextbox'>
                        <p>User/Email</p>
                        <div className="form-g">
                            <div className="input-cont">
                                <input type='text' placeholder='please enter username or email' onChange={handleInputChange} className={error ? 'error-input' : ''}/>
                                {error && <small className="error-text" >{error}</small>}                          
                            </div>
                        </div>
                        <button type='submit' onClick={handleSubmit} className='fregister-btn' disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Continue'}</button>
                    </div>
                </div>
                <p  className='fp'>You’ll Receive An Email Regarding The Password Recovery</p>
                <p style={{color:"#808081"}}>Please Check Your Email</p>
                <img src={dotr} alt='circler' className='img6'/>
                <img src={forgotb} alt='circler' className='img7'/>
                <Modal show={showModal} message={errorMessage} onClose={()=>closeModal(typeError)} type={typeError} bname={buttonname}/>

            </div>
        </div>
    );
};

export default ForgotPassword;