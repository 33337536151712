import React from "react";
import Loginwhiter from '../../assets/Loginwhiter.jpg'
import './CSS/Signup.css'
import signupim from '../../assets/signup.png'
import google from '../../assets/google.png'
import apple from '../../assets/apple.png'
import fb from '../../assets/fb.png'
import loginWhitel from '../../assets/Loginwhitel.jpg'
import topdot from '../../assets/topdot.png'
import top from '../../assets/top.jpg'
import bottomdot from '../../assets/bottomdot.png'
import bottom from '../../assets/bottom.jpg'
import { useState, useContext } from "react";
import { RegisteredEmail } from "./EmailContext";
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {Icon} from 'react-icons-kit';
import {eye} from 'react-icons-kit/feather/eye'
import Modal from "./Modal";
//import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import Otp from "./Otp";
import 'react-datepicker/dist/react-datepicker.css';

function Signup(){
  const {state} = useContext(RegisteredEmail);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    dateOfBirth: null,
    address: '',
    city: '',
    zipCode: '',
    email: '',
    password: '',
    confirmPassword: ''
});

const [errors, setErrors] = useState({});
const [errorMessage, setErrorMessage] = useState({});
const [showModal, setShowModal] = useState(false);
const [isSubmitting, setIsSubmitting] = useState(false);
const [typeError,setTypeError] = useState('');
const [buttonname,setButtonName] = useState('');
const [showOtp,setShowOtp] = useState(false);
const [showPassword, setShowPassword] = useState(false);
const [showCPassword, setShowCPassword] = useState(false);


const handleTogglePassword = () => {
    setShowPassword(prevState => !prevState);
};
const handleToggleConfirmPassword = () => {
  setShowCPassword(prevState => !prevState);
};
//const navigate = useNavigate();

const handleDateChange = (date) => {
  setFormData({ ...formData, dateOfBirth: date });
};

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData({
      ...formData,
      [name]: value,
  });
  validate(name,value)
};

const validate = (name,value) => {
  let error = '';
  switch (name) {
      case 'firstName':
          if (!value.trim()) {
              error = 'First name is required';
          }
          break;
      case 'lastName':
          if (!value.trim()) {
              error = 'Last name is required';
          }
          break;
      case 'mobile':
            if (!value.trim()) {
                error = 'Mobile no. is required';
            }
            break;
      case 'dateOfBirth':
              if (!value.trim()) {
                  error = 'DOB is required';
              }
              break;
              case 'address':
                if (!value.trim()) {
                    error = 'address is required';
                }
                break;
                case 'city':
              if (!value.trim()) {
                  error = 'City is required';
              }
              break;
              case 'zipCode':
              if (!value.trim()) {
                  error = 'Zipcode is required';
              }
              break;
              case 'email':
              if (!value.trim()) {
                  error = 'Email is required';
              }
              break;
              case 'password':
              if (!value.trim()) {
                  error = 'Password is required';
              }
              break;
              case 'confirmPassword':
              if (!value.trim()) {
                  error = 'Last name is required';
              }
              break;
      // Add other field validations as needed
      default:
          break;
  }

  setErrors({
      ...errors,
      [name]: error,
  });

};

  // const newErrors = {};
  // if (!formData.firstName) newErrors.firstName = 'Please Enter First Name';
  // if (!formData.lastName) newErrors.lastName = 'Please Enter Last Name';
  // if (!formData.mobile) newErrors.mobile = 'Please Enter Mobile No.';
  // if (!formData.dateOfBirth) newErrors.dateOfBirth = 'Please Enter Date Of Birth';
  // if (!formData.address) newErrors.address = 'Please Enter Address';
  // if (!formData.city) newErrors.city = 'Please Enter City';
  // if (!formData.zipCode) newErrors.zipCode = 'Please Enter Zip Code';
  // if (!formData.email) newErrors.email = 'Please Enter Email';
  // if (!formData.password) newErrors.password = 'Please Enter Password';
  // if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Please Enter Same Password';

  // setErrors(newErrors);
  // return Object.keys(newErrors).length === 0;


const handleSubmit = async (e) => {
  e.preventDefault();
  const newErrors = {};
  if (!/^[A-Za-z]+$/.test(formData.firstName) || formData.firstName.length<3) {
      newErrors.firstName = 'First name must contain only letters A-Z.';
  }

  if (!formData.lastName.trim() || !/^[A-Za-z]+$/.test(formData.lastName)) {
      newErrors.lastName = 'Last name must contain only letters A-Z.';
  }
  //const usMobile = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (!formData.mobile || !/^[6-9][0-9]{9}$/.test(formData.mobile)) 
    newErrors.mobile = 'Enter a valid US phone number Ex: 123-123-1234';
  if (!formData.dateOfBirth) {
    newErrors.dateOfBirth = "Date of Birth is required";
  } else {
    // Convert date to dd/MM/yyyy for validation and comparison
    const formattedDate = formData.dateOfBirth.toLocaleDateString('en-GB');
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

    if (!dateRegex.test(formattedDate)) {
      newErrors.dateOfBirth = "Date of Birth must be in dd/MM/yyyy format";
    }
  }
  if (!formData.address || !/^[A-Za-z0-9\s,.'-]{3,}$/.test(formData.address)) newErrors.address = 'Address can contain letters, numbers, and punctuation.';
  if (!formData.city || !/^[A-Za-z\s]+$/.test(formData.city)) newErrors.city = 'City must contain only letters A-Z.';
  if (!formData.zipCode || !/^\d{5}(-\d{4})?$/.test(formData.zipCode)) newErrors.zipCode = 'Zipcode must be a 5 or 9 digit number.';
  if (!formData.email || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) newErrors.email = 'Please Enter a valid email';
  if (!formData.password || !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(formData.password)) 
    newErrors.password = 'Password must be at least 8 characters long, with one uppercase letter, one number, and one special character.';
  if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Please Enter Same Password';

  // Add other field validations as needed

  if (Object.keys(newErrors).length === 0) {
      console.log('Form data:', formData);
      setIsSubmitting(true);
   var userData = {
    firstName : formData.firstName,
    lastName : formData.lastName,
    email : formData.email,
     password : formData.password,
     phone: formData.mobile,
    address: formData.address,
    city : formData.city,
    zipcode : formData.zipCode,
    dob : formData.dateOfBirth
 };
 console.log(userData);
    try {
      console.log(JSON.stringify(userData));
      // Replace 'https://your-api-endpoint/verify-otp' with your actual API endpoint
      const response = await fetch('https://seerecs.org/provider/register', {
        method: 'POST',
        mode:'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }

      if (data.success) {
        // OTP verification successful, handle success
        console.log('Registered Successfully');
      state(formData.email);
      setShowModal(true);
      setErrorMessage(data.message);
      setTypeError('Congrats!');
      setButtonName('Continue');
      
      } else {
        //setErrors('Invalid Data');
        setErrorMessage(data.message);
        setTypeError('Error');
        setButtonName('Continue');
        setShowModal(true);

      }
    } catch (error) {
      console.error('Error in Registration:', error);
      setErrorMessage(error.message);
      setTypeError('Error');
      setButtonName('Continue');
      setShowModal(true);

      //setErrors('Failed to Fetch the Data');
    } finally {
      setIsSubmitting(false);
    }
      // Submit the form
  } else {
      setErrors(newErrors);
      setErrorMessage('Please fill all the details correctly.');
      setTypeError('Error');
      setButtonName('Continue');
      setShowModal(true); // Show the modal with the error message
  }
};

const closeModal = (typeError) => {
  if(typeError === 'Error'){
    return setShowModal(false);
  } else {
    return setShowOtp(true);
  }
}; 


return(
  <div>
  {showOtp? (<Otp/>):
    <div className="signup">

      <div className="left-sectio">
        <img className = "whiterr" src={Loginwhiter} alt = 'images' />
          <div className="p">
            <h1 className="p1">Register Now</h1>
            <p className="p2">Create a new account</p>
            <img className = "signupim" src={signupim} alt = 'images' />
            <div className="social">
              <img src = {google} alt ='google'/>
              <img src = {apple} alt ='applee'/>
              <img src = {fb} alt ='facebook'/>
            </div>
          </div>
          <p>Already have a account? <a href="/login ">Login</a></p>
          <div style={{height:'200px'}}>
                    <img className="whiterl" src = {loginWhitel} alt="Whiteleft" />
                    </div>
      </div>

      <div className="right-sectio">
        <img className="dot-le" src={topdot} alt = 'logo' />
        <img className="circular-square-rig" src={top} alt = 'logo'/>

        <div className="form">
        <form onSubmit={handleSubmit} className="registration-form">
        <div className="form-text">
          <h2>Fill the below form to register</h2>
          <p></p>
          </div>
          <div className="form-group">
              <div className="input-container">
            <input type="text" placeholder="First name" name="firstName" value={formData.firstName} onChange={handleInputChange} className={errors.firstName ? 'error-input' : ''} />
            {errors.firstName && <small className="error-text">{errors.firstName}</small>}
              </div>
              <div className="input-container">
            <input type="text" placeholder="Last name" name="lastName" value={formData.lastName} onChange={handleInputChange} className={errors.lastName ? 'error-input' : ''}/>
            {errors.lastName && <small className="error-text">{errors.lastName}</small>}
            </div>
          </div>
          <div className="form-group">
            <div className="input-container">
            <input type="text" placeholder="Mobile no." name="mobile" value={formData.mobile} onChange={handleInputChange} className={errors.mobile ? 'error-input' : ''}/>
            {errors.mobile && <small className="error-text">{errors.mobile}</small>}
            </div>
            <div className="input-container">
            <DatePicker
          selected={formData.dateOfBirth}
          onChange={handleDateChange}
          dateFormat="dd-MM-yyyy"
          maxDate={new Date()}
          minDate={new Date(1950, 0, 1)}
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={100}
          placeholderText="Date of birth"
          className={errors.mobile ? 'error-input' : ''}
        />
            {errors.dateOfBirth && <small className="error-text">{errors.dateOfBirth}</small>}
            </div>
          </div>
          <div className="form-grou">
          <div className="input-container">
            <input type="text" placeholder="Address" name="address" value={formData.address} onChange={handleInputChange} className={errors.address ? 'error-input' : ''}/>
            {errors.address && <small className="error-text">{errors.address}</small>}
            </div>
          </div>
          <div className="form-group">
            <div className="input-container">
            <input type="text" placeholder="City" name="city" value={formData.city} onChange={handleInputChange} className={errors.city ? 'error-input' : ''}/>
            {errors.city && <small className="error-text">{errors.city}</small>}
            </div>
            <div className="input-container">
            <input type="text" placeholder="Zip code" name="zipCode" value={formData.zipCode} onChange={handleInputChange} className={errors.zipCode ? 'error-input' : ''}/>
            {errors.zipCode && <small className="error-text">{errors.zipCode}</small>}
            </div>
          </div>
          <div className="form-grou">
            <div className="input-container">
            <input type="email" placeholder="Email" name="email" value={formData.email} onChange={handleInputChange} className={errors.email ? 'error-input' : ''}/>
            {errors.email && <small className="error-text">{errors.email}</small>}
            </div>
          </div>
          <div className="form-grou">
            <div className="input-container">
            <div style={{ position: 'relative' }}>
            <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter Password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className={errors.password ? 'error-input' : ''}
                style={{  paddingRight: '30px' }} // Add padding for the icon
            />
            <span
                onClick={handleTogglePassword}
                style={{
                    position: 'absolute',
                    right: '20px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    color:'grey'
                }}
            >
                {showPassword ? <Icon class="absolute mr-10" icon={eye} size={22}/> : <Icon class="absolute mr-10" icon={eyeOff} size={22}/>}
            </span>
        </div>             
            {errors.password && <small className="error-text">{errors.password}</small>}
            </div>
          </div>
          <div className="form-grou">
            <div className="input-container">
            <div style={{ position: 'relative' }}>
            <input type={showCPassword ? 'text' : 'password'} placeholder="Confirm password" name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} className={errors.confirmPassword ? 'error-input' : ''}/>
            <span
                onClick={handleToggleConfirmPassword}
                style={{
                    position: 'absolute',
                    right: '20px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    color:'grey'
                }}
            >
                {showCPassword ? <Icon class="absolute mr-10" icon={eye} size={22}/> : <Icon class="absolute mr-10" icon={eyeOff} size={22}/>}
            </span>
        </div>  
            {errors.confirmPassword && <small className="error-text">{errors.confirmPassword}</small>}
            </div>
          </div>
          <div className="form-text">
          <button type="submit" className="register-btn" disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Register'}</button>
          </div>
        </form>

        </div>

      </div>
      <img className="dot-rig" src={bottomdot} alt = 'logo'/>
      <img className="circular-square-lef" src={bottom} alt = 'logo' />
      <Modal show={showModal} message={errorMessage} onClose= {()=>closeModal(typeError)} type={typeError} bname={buttonname}/>

    </div>}
    </div>
);
}

export default Signup;

// const styles = {
//   container: {
//     display: 'flex',
//     height: '100vh',
//     backgroundColor: '#f5f5f5',
//   },
//   leftContainer: {
//     flex: 1,
//     background: 'linear-gradient(135deg, #E0C3FC 0%, #8EC5FC 100%)',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     color: '#fff',
//     padding: '20px',
//     borderRadius: '20px 0 0 20px',
//   },
//   image: {
//     width: '150px',
//     height: 'auto',
//     marginBottom: '20px',
//   },
//   title: {
//     fontSize: '36px',
//     fontWeight: 'bold',
//     marginBottom: '10px',
//   },
//   subtitle: {
//     fontSize: '24px',
//     marginBottom: '10px',
//   },
//   description: {
//     fontSize: '16px',
//     textAlign: 'center',
//     maxWidth: '300px',
//   },
//   rightContainer: {
//     flex: 1,
//     backgroundColor: '#fff',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     padding: '40px',
//     borderRadius: '0 20px 20px 0',
//   },
//   welcomeText: {
//     fontSize: '28px',
//     fontWeight: 'bold',
//     marginBottom: '5px',
//   },
//   welcomeSubText: {
//     fontSize: '16px',
//     color: '#888',
//     marginBottom: '20px',
//   },
//   form: {
//     width: '100%',
//     maxWidth: '350px',
//     display: 'flex',
//     flexDirection: 'column',
//   },
//   input: {
//     width: '100%',
//     padding: '10px',
//     marginBottom: '15px',
//     borderRadius: '5px',
//     border: '1px solid #ddd',
//     fontSize: '16px',
//   },
//   options: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     marginBottom: '20px',
//     fontSize: '14px',
//     color: '#888',
//   },
//   forgotPassword: {
//     color: '#888',
//     textDecoration: 'none',
//   },
//   loginButton: {
//     backgroundColor: '#7f00ff',
//     color: '#fff',
//     padding: '10px',
//     borderRadius: '5px',
//     fontSize: '16px',
//     cursor: 'pointer',
//     border: 'none',
//   },
//   orContinueWith: {
//     marginTop: '30px',
//     fontSize: '16px',
//     color: '#888',
//   },
//   socialLogin: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     width: '150px',
//     marginTop: '10px',
//   },
//   icon: {
//     fontSize: '24px',
//     color: '#888',
//     cursor: 'pointer',
//   },
//   registerText: {
//     marginTop: '30px',
//     fontSize: '14px',
//     color: '#888',
//   },
//   registerLink: {
//     color: '#7f00ff',
//     textDecoration: 'none',
//     fontWeight: 'bold',
//   },
// };

