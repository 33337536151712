import React from 'react'
import forgotr from '../../assets/Loginwhiter.jpg'
import forgot from '../../assets/otp.png'
import forgotl from '../../assets/Loginwhitel.jpg'
import circler from '../../assets/top.jpg'
import dotl from '../../assets/topdot.png'
import dotr from '../../assets/bottomdot.png'
import forgotb from '../../assets/bottom.jpg'
import { useState, useEffect, useContext } from 'react'
import Modal from './Modal'
import { RegisteredEmail } from './EmailContext'
import { useNavigate } from 'react-router-dom'
import './CSS/Otp.css'

function Otp(){
    const [otp, setOtp] = useState(['', '', '', '','','']);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [timer, setTimer] = useState(30);
    const [canResend, setCanResend] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [typeError,setTypeError] = useState('');
    const [buttonname,setButtonName] = useState('');
    const navigate = useNavigate();
    const {cemail} = useContext(RegisteredEmail);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setCanResend(true);
    }
  }, [timer]);

  const closeModal = (typeError) => {
    if(typeError === 'Error' || typeError === 'Success'){
      return setShowModal(false);
    } else {
      return  navigate('/login');
    }
  };  


  const handleResendOtp = async () => {
    if (canResend) {
      setOtp(['', '', '', '','','']);
      setTimer(30);
      setCanResend(false);
      setError('');
      try {
        // Replace 'https://your-api-endpoint/verify-otp' with your actual API endpoint
        const response = await fetch('https://seerecs.org/provider/resendOTP', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode:'cors',
          body: JSON.stringify({ email : cemail }),
        });
        const data = await response.json();
  
        if (!response.ok) {
          console.log("Response error data: ", data);
          
          throw new Error(data.message);
        }
  
        if (data.success) {
          // OTP verification successful, handle success
          console.log('OTP verified successfully');
          setErrorMessage(data.message);
          setTypeError('Success');
          setButtonName('Continue');
          setShowModal(true);
  
        } else {
          setError('Invalid OTP');
          setErrorMessage(data.message);
          setTypeError('Error');
          setButtonName('Continue');
          setShowModal(true);
        }
      } catch (error) {
        console.error('Error verifying OTP:', error);
        setError('Failed to verify OTP');
        setShowModal(true);
        setErrorMessage(error.message);
        setTypeError('Error');
        setButtonName('Continue');
      } finally {
        setIsSubmitting(false);
      }
      setTypeError('Error');
      setErrorMessage('OTP sent to email successfully');
      setButtonName('Continue');
      setShowModal(true);
      console.log('OTP Resent');
      // Here you can make an API call to resend the OTP
    }
  };

    const handleOtpChange = (index, event) => {
    const { value } = event.target;
    const newOtp = [...otp];
    newOtp[index] = value;
    if (value.length === 1 && index < otp.length - 1) {
        // Move to the next input field
        document.getElementById(`otp-input-${index + 1}`).focus();
    } else if (value === '' && index > 0) {
        // If the value is empty, move to the previous input field
        document.getElementById(`otp-input-${index - 1}`).focus();
    }
    setOtp(newOtp);
    event.preventDefault();
};


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (otp.some((digit) => digit === '')) {
      setError('Please enter all 6 digits');
      setErrorMessage('Please enter all 6 digits code');
      setTypeError('Error');
      setButtonName('Continue');
      setShowModal(true);
      return;
    }

    setIsSubmitting(true);
    var data = JSON.stringify({ email : cemail, otp: otp.join('') });
    console.log(data);
    try {
      // Replace 'https://your-api-endpoint/verify-otp' with your actual API endpoint
      const response = await fetch('https://seerecs.org/provider/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode:'cors',
        body: JSON.stringify({ email : cemail, otp: otp.join('') }),
      });
      const data = await response.json();

      if (!response.ok) {
        console.log("Response error data: ", data);
        
        throw new Error(data.message || 'OTP verification failed');
      }

      if (data.success) {
        // OTP verification successful, handle success
        console.log('OTP verified successfully');
        setErrorMessage(data.message);
        setTypeError('Congrats!');
        setButtonName('SignIn');
        setShowModal(true);

      } else {
        setError('Invalid OTP');
        setErrorMessage(data.message);
        setTypeError('Error');
        setButtonName('Continue');
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('Failed to verify OTP');
      setShowModal(true);
      setErrorMessage(error.message);
      setTypeError('Error');
      setButtonName('Continue');
    } finally {
      setIsSubmitting(false);
    }
  };

    return(
        <div className='ocontainer'>
            <div className='oleft-section'>
                <img src ={forgotr} alt='vertical' className='img1'/>
                <img src={forgot} alt='doctor' className='img2'/>
                <img src={forgotl} alt='doctor' className='img3'/>
            </div>
            <div className='oright-section'>
                <img src={circler} alt= 'circler' className='img4'/>
                <img src={dotl} alt= 'circler' className='img5'/>
                <div className='ocenter'>
                    <h2>Verification Code</h2>
                    <p>For maximum safety please proceed</p>
                        <form onSubmit={handleSubmit}>
                            <div className='textboxes'>
                                {otp.map((digit, index) => (
                                    <input key={index} id={`otp-input-${index}`} type="text" value={digit} onChange={(event) => handleOtpChange(index, event)} maxLength={1}
                                    className={error ? 'error-inpu' : ''}/>
                                ))}
                            </div>
                            {error && <p style={{fontSize:14,color:'red',alignSelf:'center',marginTop:20,marginLeft:30}}>{error}</p>}
                            <button type="submit" className='obutton' disabled={isSubmitting}>
                                {isSubmitting ? 'Submitting...' : 'Continue'}
                            </button>
                            <div className='down'>
                                <p style={{fontSize:16,fontWeight:'bolder',color:'#141047'}}>Enter your code (00: {timer})</p>
                                <p style={{fontSize:14,color:'#737081',marginLeft:6}}>Not Received?{' '} 
                                    <span style={{ color: '#CD0304' }}>
                                    {canResend ? (
                                        <button onClick={handleResendOtp} className='obutton1' disabled={!canResend}>RESEND</button>
                                        ) : ('RESEND')}
                                    </span>
                                </p>
                            </div>
                        </form>
                </div>

                <img src={dotr} alt='circler' className='img6'/>
                <img src={forgotb} alt='circler' className='img7'/>
                <Modal show={showModal} message={errorMessage} onClose={()=>closeModal(typeError)} type={typeError} bname={buttonname}/>

            </div>
        </div>
    );
}

export default Otp

// App.js

// const OTPInput = ({ length = 4 }) => {
//   const [otp, setOtp] = useState(Array(length).fill(''));
//   const inputs = useRef([]);

//   const handleChange = (e, index) => {
//     const { value } = e.target;

//     // Only allow single digit input
//     if (value.match(/^\d$/)) {
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);

//       // Move focus to the next input
//       if (index < length - 1) {
//         inputs.current[index + 1].focus();
//       }
//     }

//     // Move focus to previous input on backspace
//     if (value === '' && index > 0) {
//       inputs.current[index - 1].focus();
//     }
//   };

//   const handleKeyDown = (e, index) => {
//     if (e.key === 'Backspace' && otp[index] === '') {
//       // Move focus to previous input on backspace if current input is empty
//       if (index > 0) {
//         inputs.current[index - 1].focus();
//       }
//     }
//   };

//   return (
//     <div style={{ display: 'flex', justifyContent: 'center' }}>
//       {otp.map((_, index) => (
//         <input
//           key={index}
//           type="text"
//           maxLength="1"
//           value={otp[index]}
//           onChange={(e) => handleChange(e, index)}
//           onKeyDown={(e) => handleKeyDown(e, index)}
//           ref={(el) => (inputs.current[index] = el)}
//           style={{
//             width: '40px',
//             height: '40px',
//             margin: '0 5px',
//             textAlign: 'center',
//             fontSize: '18px',
//             border: '1px solid #ccc',
//             borderRadius: '4px',
//           }}
//         />
//       ))}
//     </div>
//   );
// };
