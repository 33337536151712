import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = () => {
  const token = Cookies.get('token');

  // If token exists, render the child routes/components; otherwise, redirect to login
  return token ? <Outlet /> : <Navigate to="/login" replace={true} />;
};

export default ProtectedRoute;

