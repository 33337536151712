import React from "react";
import './CSS/Login.css'
import Loginwhiter from '../../assets/Loginwhiter.jpg';
import topdot from '../../assets/topdot.png'
import top from '../../assets/top.jpg'
import bottomdot from '../../assets/bottomdot.png'
import bottom from '../../assets/bottom.jpg'
import crecs from '../../assets/LoginCRECS.png'
import dragon from '../../assets/LoginDragon.jpg'
import loginWhitel from '../../assets/Loginwhitel.jpg'
import { useState } from "react";
import Cookies from 'js-cookie';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {Icon} from 'react-icons-kit';
import {eye} from 'react-icons-kit/feather/eye'
import { useNavigate } from "react-router-dom";
import google from '../../assets/google.png'
import apple from '../../assets/apple.png'
import fb from '../../assets/fb.png'
import Modal from "./Modal";

function Login(){
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    isConfirmed: false,
  });

  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [typeError,setTypeError] = useState('');
  const [buttonname,setButtonName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
      setShowPassword(prevState => !prevState);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange   
 = (e) => {
    setFormData({
      ...formData,   

      isConfirmed: e.target.checked,
    });
  };
  const handleSubmit = async(e) => {
    e.preventDefault();

    const newErrors = {};

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }

    // Password validation (at least 8 characters, one uppercase, one number, one special character)
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      newErrors.password = 'Password must be 8 characters long';
    }

    // Confirmation checkbox
    if (!formData.isConfirmed) {
      newErrors.isConfirmed = 'Please confirm the terms and conditions';
    }

    if (Object.keys(newErrors).length === 0) {
      setErrors(newErrors);
      setIsSubmitting(true);
      console.log('Form submitted successfully:', formData);
      try {
        console.log(JSON.stringify(formData));
        // Replace 'https://your-api-endpoint/verify-otp' with your actual API endpoint
        const response = await fetch('https://seerecs.org/provider/login', {
          method: 'POST',
          mode:'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        const data = await response.json();

        
  
        if (!response.ok) {
          throw new Error(data.message);
        }
  
        if (data.response.success && data.response.success) {
        const provider = data.response.provider;
        Cookies.set('token', provider.issuedToken, { expires: 1 }); // Expires in 7 days
        // Also store user data in localStorage for later access
        localStorage.setItem('user', JSON.stringify(data.response));
        //console.log(JSON.stringify(data.headers));
        //const header = data.headers;
        // OTP verification successful, handle success
        console.log('Login Successfully');
        setShowModal(true);
        setErrorMessage(data.response.message);
        setTypeError('Congrats!');
        setButtonName('Continue');
        
        } else {
          //setErrors('Invalid Data');
          setErrorMessage(data.message);
          setTypeError('Error');
          setButtonName('Continue');
          setShowModal(true);
  
        }
      } catch (error) {
        console.error('Error in Login:', error);
        setErrorMessage(error.message);
        setTypeError('Error');
        setButtonName('Continue');
        setShowModal(true);
  
        //setErrors('Failed to Fetch the Data');
      } finally {
        setIsSubmitting(false);
      }
        // Submit the form
    }

     else {
      // Assuming you want to submit the form data here
      setErrors(newErrors);
      setErrorMessage(formData.isConfirmed?'Invalid username or password': 'Please confirm remember me');
      setTypeError('Error');
      setButtonName('Continue');
      setShowModal(true);
    }
  };

  const closeModal = (typeError) => {
    if(typeError === 'Error'){
      return setShowModal(false);
    } else {
      return navigate('/homepage');
    }
  }; 
  // const redirecteModal = () => {
  //   navigate
  // };
return(
    <div className="logincon">
        <div className="left-sectio">
            <img className = "whiterr" src={Loginwhiter} alt = 'images' />
            
            <div className="crecs">
                <div className="crecsflex">
                    <img className="imageSerecs" src={crecs} alt = 'crecs' />
                    <p className="subtitle">View Health Records</p>
                    <p className="description">
                        C-Recs is a healthcare app that helps users manage and track their health and wellness
                    </p>
                </div>
                    <img className="imageDragon" src = {dragon} alt="dragon"/>
                    <p className="psec"></p>
            </div>
                    <div style={{height:'200px'}}>
                    <img className="whiterl" src = {loginWhitel} alt="Whiteleft" />
                    </div>
        </div>
        
        <div className="right-section">
      <img className="dot-lef" src={topdot} alt = 'logo' />
      <img className="circular-square-righ" src={top} alt = 'logo'/>

        <h2>Hello Again!</h2>
        <p className="ptag">Welcome back you've</p>
        <p className="ptag">missed again</p>
        
        <div className="form-gro">
            <div className="input-containe">
            <input type="email" placeholder="Email" name="email" value={formData.email} onChange={handleInputChange} className={errors.email ? 'error-inpu' : ''}/>
            {errors.email && <small className="error-text">{errors.email}</small>}
            </div>
          </div>
          <div className="form-gro">
            <div className="input-containe">
            <div style={{ position: 'relative' }}>
            <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter Password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className={errors.password ? 'error-input' : ''}
                style={{  paddingRight: '30px' }} // Add padding for the icon
            />
            <span
                onClick={handleTogglePassword}
                style={{
                    position: 'absolute',
                    right: '20px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    color:'grey'
                }}
            >
                {showPassword ? <Icon class="absolute mr-10" icon={eye} size={22}/> : <Icon class="absolute mr-10" icon={eyeOff} size={22}/>}
            </span>
        </div>            
        {errors.password && <small className="error-text">{errors.password}</small>}
            </div>
          </div>

        <div className="forgetPassword">
             <div className="RememberMe">
              <div className="inpu-containe">
                <label className="checkbox-label">
                <input type="checkbox" className="custom-checkbox" value={formData.isConfirmed} onChange={handleCheckboxChange}/>
                <span style={{fontSize:15}}>Remember me</span>
                </label>
              </div>
            </div>
            <a className="forgot-password-link" href="/forgotpassword">Forgot Password!</a>
        </div>
        {errors.isConfirmed && <p style={{color:'red'}}>{errors.isConfirmed}</p>}
       <div style={{paddingTop:20}}></div>
       <button type="submit" onClick={handleSubmit} className="lbutton" disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Login'}</button>
       <div style={{paddingTop:10}}></div>

        <div className="continue">
            <div className="line"></div>
            <p>Or continue with</p>
            <div className="line"></div> 
        </div>

        <div className="social">
          <img src = {google} alt ='google'/>
          <img src = {apple} alt ='applee'/>
          <img src = {fb} alt ='facebook'/>
        </div>
        <div className="">
        <p >
          Not a member? <a href="/signup" style={{textDecoration:'none',color:'#2A77DB'}}>Register now</a>
        </p>
        </div>
        <img className="dot-righ" src={bottomdot} alt = 'logo'/>
        <img className="circular-square-lef" src={bottom} alt = 'logo' />
        <Modal show={showModal} message={errorMessage} onClose= {()=>closeModal(typeError)} type={typeError} bname={buttonname}/>

      </div>

    </div>
);
}

export default Login;